.layout-profile-sidebar {
    &.p-sidebar {
        width: 25rem;

        .p-sidebar-content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
