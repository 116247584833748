/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";
/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";
// @import "assets/layout/styles/theme/theme-light/blue/theme.css";

:root {
    --surface-a: transparent;
    --surface-b: rgba(68, 72, 109, 0.07);
    --surface-c: rgba(68, 72, 109, 0.12);
    --surface-d: rgba(68, 72, 109, 0.17);
    --surface-e: transparent;
    --surface-f: transparent;
    --text-color: #44486d;
    --text-color-secondary: #676b89;
    --primary-color: rgb(29, 112, 183);
    --primary-color-text: #ffffff;
    --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
    --surface-0: #ffffff;
    --surface-50: #e1e2e8;
    --surface-100: #cbccd6;
    --surface-200: #b4b6c5;
    --surface-300: #9ea0b3;
    --surface-400: #878aa2;
    --surface-500: #717490;
    --surface-600: #5a5e7f;
    --surface-700: #44486d;
    --surface-800: #3c3f60;
    --surface-900: #343753;
    --gray-50: #e1e2e8;
    --gray-100: #cbccd6;
    --gray-200: #b4b6c5;
    --gray-300: #9ea0b3;
    --gray-400: #878aa2;
    --gray-500: #717490;
    --gray-600: #5a5e7f;
    --gray-700: #44486d;
    --gray-800: #3c3f60;
    --gray-900: #343753;
    --content-padding: 1.143rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --surface-ground: linear-gradient(180deg, #f6f9fc 0%, #ececf9 100%);
    --surface-section: #ffffff;
    --surface-card: rgba(255, 255, 255, 0.9);
    --surface-overlay: #ececf9;
    --surface-border: rgba(68, 72, 109, 0.2);
    --surface-hover: rgba(68, 72, 109, 0.07);
    --maskbg: rgb(0 0 0 / 46%);
    --focus-ring: 0 none;
}

@media screen and (min-width: 523px) {
    .sm\:justify-content-center {
        justify-content: center !important;
    }
}

@media screen and (min-width: 1024px) {
    .xl\:justify-content-start {
        justify-content: start !important;
    }
}

@media screen and (min-width: 523px) {
    .sm\:none {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .md\:none {
        display: none !important;
    }
}

@media screen and (max-width: 992px) {
    .lg\:none {
        display: none !important;
    }
}

@media screen and (min-width: 1024px) {
    .xl\:in {
        display: inline !important;
    }
}

* {
    box-sizing: border-box;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.underline {
    text-decoration: underline;
}

label {
    color: rgb(29, 112, 183);
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    text-transform: uppercase;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
}

.p-component-overlay {
    background-color: transparent;
    transition-duration: 0.5s;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.p-disabled,
.p-disabled * {
    cursor: not-allowed !important;
    pointer-events: none;
}

.p-error {
    color: #f10d0d;
    font-weight: 500;
}

.p-text-secondary {
    color: #676b89;
}

.pi {
    font-size: 1.2rem;
}

.p-link {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    border-radius: 6px;
}

.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent;
    }

    to {
        background-color: var(--maskbg);
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var(--maskbg);
    }

    to {
        background-color: transparent;
    }
}

:root {
    --blue-50: #f4fafe;
    --blue-100: #cae6fc;
    --blue-200: #a0d2fa;
    --blue-300: #75bef8;
    --blue-400: #4baaf5;
    --blue-500: #2196f3;
    --blue-600: #1c80cf;
    --blue-700: #1769aa;
    --blue-800: #125386;
    --blue-900: #0d3c61;
    --green-50: #f6fbf6;
    --green-100: #d4ecd5;
    --green-200: #b2ddb4;
    --green-300: #90cd93;
    --green-400: #6ebe71;
    --green-500: #4caf50;
    --green-600: #419544;
    --green-700: #357b38;
    --green-800: #2a602c;
    --green-900: #1e4620;
    --yellow-50: #fffcf5;
    --yellow-100: #fef0cd;
    --yellow-200: #fde4a5;
    --yellow-300: #fdd87d;
    --yellow-400: #fccc55;
    --yellow-500: #fbc02d;
    --yellow-600: #d5a326;
    --yellow-700: #b08620;
    --yellow-800: #8a6a19;
    --yellow-900: #644d12;
    --cyan-50: #f2fcfd;
    --cyan-100: #c2eff5;
    --cyan-200: #91e2ed;
    --cyan-300: #61d5e4;
    --cyan-400: #30c9dc;
    --cyan-500: #00bcd4;
    --cyan-600: #00a0b4;
    --cyan-700: #008494;
    --cyan-800: #006775;
    --cyan-900: #004b55;
    --pink-50: #fef4f7;
    --pink-100: #fac9da;
    --pink-200: #f69ebc;
    --pink-300: #f1749e;
    --pink-400: #ed4981;
    --pink-500: #e91e63;
    --pink-600: #c61a54;
    --pink-700: #a31545;
    --pink-800: #801136;
    --pink-900: #5d0c28;
    --indigo-50: #f5f6fb;
    --indigo-100: #d1d5ed;
    --indigo-200: #acb4df;
    --indigo-300: #8893d1;
    --indigo-400: #6372c3;
    --indigo-500: #3f51b5;
    --indigo-600: #36459a;
    --indigo-700: #2c397f;
    --indigo-800: #232d64;
    --indigo-900: #192048;
    --teal-50: #f2faf9;
    --teal-100: #c2e6e2;
    --teal-200: #91d2cc;
    --teal-300: #61beb5;
    --teal-400: #30aa9f;
    --teal-500: #009688;
    --teal-600: #008074;
    --teal-700: #00695f;
    --teal-800: #00534b;
    --teal-900: #003c36;
    --orange-50: #fff8f2;
    --orange-100: #fde0c2;
    --orange-200: #fbc791;
    --orange-300: #f9ae61;
    --orange-400: #f79530;
    --orange-500: #f57c00;
    --orange-600: #d06900;
    --orange-700: #ac5700;
    --orange-800: #874400;
    --orange-900: #623200;
    --bluegray-50: #f7f9f9;
    --bluegray-100: #d9e0e3;
    --bluegray-200: #bbc7cd;
    --bluegray-300: #9caeb7;
    --bluegray-400: #7e96a1;
    --bluegray-500: #607d8b;
    --bluegray-600: #526a76;
    --bluegray-700: #435861;
    --bluegray-800: #35454c;
    --bluegray-900: #263238;
    --purple-50: #faf4fb;
    --purple-100: #e7cbec;
    --purple-200: #d4a2dd;
    --purple-300: #c279ce;
    --purple-400: #af50bf;
    --purple-500: #9c27b0;
    --purple-600: #852196;
    --purple-700: #6d1b7b;
    --purple-800: #561561;
    --purple-900: #3e1046;
    --primary-50: #f3f9ff;
    --primary-100: #c5e3ff;
    --primary-200: #98cdfe;
    --primary-300: #6ab7fe;
    --primary-400: #3da1fd;
    --primary-500: rgb(29, 112, 183);
    --primary-600: #0d76d7;
    --primary-700: #0b61b1;
    --primary-800: #084c8b;
    --primary-900: #063865;
    --red-50: #fff5f5;
    --red-100: #ffd0ce;
    --red-200: #ffaca7;
    --red-300: #ff8780;
    --red-400: #ff6259;
    --red-500: #ff3d32;
    --red-600: #d9342b;
    --red-700: #b32b23;
    --red-800: #8c221c;
    --red-900: #661814;
}

.p-autocomplete .p-autocomplete-loader {
    right: 0.571rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.2145rem 0.571rem;
    gap: 0.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: transparent;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.2145rem 0;
}

.p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-input-token
    input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 1rem;
    color: #44486d;
    padding: 0;
    margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.2145rem 0.571rem;
    background: rgb(29, 112, 183);
    color: #ffffff;
    border-radius: 6px;
}

.p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-token
    .p-autocomplete-token-icon {
    margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #f10d0d;
}

.p-autocomplete-panel {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0.286rem;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.429rem 0.286rem;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
    background: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2.142rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: #676b89;
    right: 0.571rem;
}

p-autocomplete.p-autocomplete-clearable
    .p-autocomplete-dd
    .p-autocomplete-clear-icon {
    color: #676b89;
    right: 2.857rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #f10d0d;
}

.p-datepicker {
    padding: 0.857rem;
    background: transparent;
    color: #44486d;
    border: 1px solid transparent;
    border-radius: 6px;
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #ececf9;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: transparent;
}

.p-datepicker .p-datepicker-header {
    padding: 0 0 0.75rem 0;
    color: #44486d;
    background: transparent;
    font-weight: 400;
    margin: 0;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #44486d;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    font-weight: 400;
    padding: 0.357rem;
}

.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: rgb(29, 112, 183);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}

.p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.p-datepicker table th {
    padding: 0.357rem;
}

.p-datepicker table th > span {
    width: 2.571rem;
    height: 2.571rem;
}

.p-datepicker table td {
    padding: 0.1rem;
}

.p-datepicker table td > span {
    width: 2.571rem;
    height: 2.571rem;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    border: 2px solid transparent;
}

.p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datepicker table td.p-datepicker-today > span {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
    border-color: rgba(68, 72, 109, 0.12);
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid rgba(68, 72, 109, 0.17);
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}

.p-datepicker .p-timepicker {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
    font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}

.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.357rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.357rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid rgba(68, 72, 109, 0.17);
    padding-right: 0.857rem;
    padding-left: 0.857rem;
    padding-top: 0;
    padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
}

.p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.12);
}

.p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.12);
}

.p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.12);
}

.p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2.142rem;
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: #676b89;
    right: 0.571rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
    color: #676b89;
    right: 2.857rem;
}

@media screen and (max-width: 769px) {
    .p-datepicker table th,
    .p-datepicker table td {
        padding: 0;
    }
}

.p-cascadeselect {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: transparent;
}

.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.429rem 0.571rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #8a8ea6;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #676b89;
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-cascadeselect-panel {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.286rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-item-content {
    padding: 0.429rem 0.286rem;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-group-icon {
    font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #f10d0d;
}

.p-input-filled .p-cascadeselect {
    background: transparent;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: transparent;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: rgba(68, 72, 109, 0.07);
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: 0.571rem;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: #676b89;
    right: 2.357rem;
}

.p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
    box-shadow: none;
    border-radius: 0;
    padding: 0.25rem 0 0.25rem 0.5rem;
}

.p-overlay-modal
    .p-cascadeselect-item-active
    > .p-cascadeselect-item-content
    .p-cascadeselect-group-icon {
    transform: rotate(90deg);
}

.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #8a8ea6;
    background: rgba(68, 72, 109, 0.07);
    width: 20px;
    height: 20px;
    color: #44486d;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: rgb(29, 112, 183);
    background: rgb(29, 112, 183);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: transparent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: rgb(29, 112, 183);
    background: rgb(29, 112, 183);
    color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #f10d0d;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: transparent;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: rgb(29, 112, 183);
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: transparent;
}

.p-input-filled
    .p-checkbox:not(.p-checkbox-disabled)
    .p-checkbox-box.p-highlight:hover {
    background: #0270d5;
}

.p-checkbox-label {
    margin-left: 0.5rem;
}

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
    padding: 0.2145rem 0.571rem;
    gap: 0.5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: transparent;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.2145rem 0.571rem;
    background: rgb(29, 112, 183);
    color: #ffffff;
    border-radius: 6px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.2145rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 1rem;
    color: #44486d;
    padding: 0;
    margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #f10d0d;
}

p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.571rem;
}

p-chips.p-chips-clearable .p-chips-clear-icon {
    color: #676b89;
    right: 0.571rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
}

.p-colorpicker-panel {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid #191919;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #191919;
}

.p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
        0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: transparent;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 2.571rem;
}

.p-dropdown .p-dropdown-label {
    background: white;
    border: 0 none;
}

.p-fluid .p-inputtextarea {
    width: 100%;
    background: transparent;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #8a8ea6;
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: rgb(29, 112, 183);
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-dropdown .p-dropdown-clear-icon {
    color: #5e5e5e94;
    right: 2.357rem;
    margin-right: 0.5rem;
}

.p-dropdown-panel {
    background: whitesmoke;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown-panel .p-dropdown-header {
    padding: 0.429rem 0.286rem;
    border-bottom: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.571rem;
    margin-right: -1.571rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.286rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.429rem 0.286rem;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
    background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #f10d0d;
}

.p-input-filled .p-dropdown {
    background: transparent;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: transparent;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: rgba(68, 72, 109, 0.07);
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
    background: transparent;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid rgba(68, 72, 109, 0.17);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #676b89;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #676b89;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #676b89;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover {
    color: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover
    .ql-stroke {
    stroke: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover
    .ql-fill {
    fill: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label {
    color: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label
    .ql-stroke {
    stroke: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label
    .ql-fill {
    fill: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options {
    background: #ececf9;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 0.286rem;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options
    .ql-picker-item {
    color: #44486d;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options
    .ql-picker-item:hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded:not(.ql-icon-picker)
    .ql-picker-item {
    padding: 0.429rem 0.286rem;
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid rgba(68, 72, 109, 0.17);
}

.p-editor-container .p-editor-content .ql-editor {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #44486d;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #44486d;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #44486d;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: rgb(29, 112, 183);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: rgb(29, 112, 183);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: rgb(29, 112, 183);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
    .ql-snow.ql-toolbar
    .ql-picker-label.ql-active
    .ql-picker-label,
.p-editor-container
    .ql-snow.ql-toolbar
    .ql-picker-item.ql-selected
    .ql-picker-label {
    color: rgb(29, 112, 183);
}

.p-inputgroup-addon {
    background: rgba(68, 72, 109, 0.07);
    color: #676b89;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: 0.429rem 0.571rem;
    min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid transparent;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup
    > .p-inputwrapper
    > .p-component
    > .p-inputtext
    + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.286rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #f10d0d;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2.142rem;
}

p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: #f10d0d;
    right: 0.571rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #f10d0d;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2.142rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: #676b89;
    right: 0.571rem;
}

p-inputnumber {
    display: initial !important;
}

p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-stacked
    .p-inputnumber-clear-icon {
    right: 2.857rem;
}

p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-horizontal
    .p-inputnumber-clear-icon {
    right: 2.857rem;
}

.p-inputswitch {
    width: 2.714rem;
    height: 1.429rem;
}

.p-inputswitch .p-inputswitch-slider {
    background: rgba(68, 72, 109, 0.07);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 12px;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #676b89;
    width: 1.143rem;
    height: 1.143rem;
    left: 0.25rem;
    margin-top: -0.5715rem;
    border-radius: 8px;
    transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.143rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: rgba(68, 72, 109, 0.12);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: rgb(29, 112, 183);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: #027eef;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
    border-color: #f10d0d;
}

.p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 1rem;
    color: #44486d;
    background: white;
    padding: 0.429rem 0.571rem;
    border: 1px solid #d3d3d352;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
        0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.p-inputtext:enabled:hover {
    border-color: transparent;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-inputtext.ng-dirty.ng-invalid {
    border-color: #f10d0d;
}

.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.375375rem 0.499625rem;
}

.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.53625rem 0.71375rem;
}

.p-float-label > label {
    left: 0.571rem;
    color: #8a8ea6;
    transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
    color: #f10d0d;
}

.p-input-icon-left > i:first-of-type {
    left: 0.571rem;
    color: #676b89;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2.142rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2.142rem;
}

.p-input-icon-right > i:last-of-type {
    right: 0.571rem;
    color: #676b89;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2.142rem;
}

::-webkit-input-placeholder {
    color: #8a8ea6;
}

:-moz-placeholder {
    color: #8a8ea6;
}

::-moz-placeholder {
    color: #8a8ea6;
}

:-ms-input-placeholder {
    color: #8a8ea6;
}

.p-input-filled .p-inputtext {
    background-color: transparent;
}

.p-input-filled .p-inputtext:enabled:hover {
    background-color: transparent;
}

.p-input-filled .p-inputtext:enabled:focus {
    background-color: rgba(68, 72, 109, 0.07);
}

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.375375rem 0.499625rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.53625rem 0.71375rem;
}

.p-listbox {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
    border: 1px solid transparent;
    border-radius: 6px;
}

.p-listbox .p-listbox-header {
    padding: 0.429rem 0.286rem;
    border-bottom: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.571rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
    padding: 0.286rem;
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.429rem 0.286rem;
    border: 0 none;
    color: #44486d;
    transition: box-shadow 0.2s;
    border-radius: 4px;
    justify-content: center;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
    background: transparent;
}

.p-listbox:not(.p-disabled)
    .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
    text-decoration: underline;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #f10d0d;
}

.p-multiselect {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: transparent;
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-multiselect .p-multiselect-label {
    padding: 0.429rem 0.571rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    width: 14rem;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #8a8ea6;
}

.p-multiselect.p-multiselect-chip .p-multiselect-label {
    gap: 0.5rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.2145rem 0.571rem;
    background: rgb(29, 112, 183);
    color: #ffffff;
    border-radius: 6px;
}

.p-multiselect.p-multiselect-chip
    .p-multiselect-token
    .p-multiselect-token-icon {
    margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #676b89;
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.2145rem 0.571rem;
}

.p-multiselect-panel {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.429rem 0.286rem;
    border-bottom: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-inputtext {
    padding-right: 1.571rem;
}

.p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-multiselect-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-multiselect-panel .p-multiselect-items {
    padding: 0.286rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.429rem 0.286rem;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
    background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #f10d0d;
}

.p-input-filled .p-multiselect {
    background: transparent;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: transparent;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: rgba(68, 72, 109, 0.07);
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.571rem;
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
    color: #676b89;
    right: 2.357rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #f10d0d;
}

.p-password-panel {
    padding: 1.143rem;
    background: transparent;
    color: #44486d;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}

.p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: rgba(68, 72, 109, 0.12);
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #d32f2f;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #fbc02d;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #689f38;
}

p-password.p-password-clearable .p-password-input {
    padding-right: 2.142rem;
}

p-password.p-password-clearable .p-password-clear-icon {
    color: #676b89;
    right: 0.571rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 3.713rem;
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: #676b89;
    right: 2.142rem;
}

.p-radiobutton {
    width: 20px;
    height: 20px;
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #8a8ea6;
    background: rgba(68, 72, 109, 0.07);
    width: 20px;
    height: 20px;
    color: #44486d;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: transparent;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: rgb(29, 112, 183);
    background: rgb(29, 112, 183);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #0270d5;
    background: #0270d5;
    color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #f10d0d;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: transparent;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: transparent;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: rgb(29, 112, 183);
}

.p-input-filled
    .p-radiobutton
    .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #0270d5;
}

.p-radiobutton-label {
    margin-left: 0.5rem;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
    border-color: #ffffff;
}

.p-rating .p-rating-icon {
    color: #44486d;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    font-size: 1.286rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
    color: #f10d0d;
}

.p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-rating .p-rating-icon:first-child {
    margin-left: 0;
}

.p-rating .p-rating-icon.pi-star-fill {
    color: rgb(29, 112, 183);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: rgb(29, 112, 183);
}

.p-rating:not(.p-disabled):not(.p-readonly)
    .p-rating-icon.p-rating-cancel:hover {
    color: #f10d0d;
}

.p-highlight .p-rating .p-rating-icon {
    color: #ffffff;
}

.p-selectbutton .p-button {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    color: #44486d;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    vertical-align: baseline;
    margin-right: 0.5rem !important;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: #676b89;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.12);
    border-color: transparent;
    color: #44486d;
}

.p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
.p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: #676b89;
}

.p-selectbutton .p-button.p-highlight {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: rgba(29, 111, 183, 0.494);
    border-color: rgb(29, 112, 183, 0.494);
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #f10d0d;
}

.p-slider {
    background: rgba(68, 72, 109, 0.12);
    border: 0 none;
    border-radius: 6px;
}

.p-slider.p-slider-horizontal {
    height: 0.429rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
    width: 0.429rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 4px solid rgb(29, 112, 183);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-slider .p-slider-range {
    background: rgb(29, 112, 183);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
}

.p-togglebutton.p-button {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    color: #44486d;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: #676b89;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.12);
    border-color: transparent;
    color: #44486d;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: #676b89;
}

.p-togglebutton.p-button.p-highlight {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
    color: #ffffff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #027eef;
    border-color: #027eef;
    color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #f10d0d;
}

.p-treeselect {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
}

.p-treeselect:not(.p-disabled):hover {
    border-color: transparent;
}

.p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    border-color: rgb(29, 112, 183);
}

.p-treeselect .p-treeselect-label {
    padding: 0.429rem 0.571rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: #8a8ea6;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.2145rem 0.571rem;
    margin-right: 0.5rem;
    background: rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #676b89;
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: #f10d0d;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.2145rem 0.571rem;
}

.p-treeselect-panel {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-treeselect-panel .p-treeselect-header {
    padding: 0.429rem 0.286rem;
    border-bottom: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter {
    padding-right: 1.571rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter {
    padding-right: 3.142rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter-clear-icon {
    right: 2.142rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
    background: transparent;
}

.p-input-filled .p-treeselect {
    background: transparent;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: transparent;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: rgba(68, 72, 109, 0.07);
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.571rem;
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: #676b89;
    right: 2.286rem;
}

.p-button {
    color: #ffffff;
    background: rgb(29, 112, 183);
    border: 1px solid rgb(29, 112, 183);
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-button:enabled:hover {
    background: rgb(24, 121, 204);
    color: #ffffff;
    border-color: rgb(24, 121, 204);
}

.p-button:enabled:active {
    background: #0270d5;
    color: #ffffff;
    border-color: #0270d5;
}

.p-button.p-button-outlined {
    background-color: transparent;
    color: rgb(29, 112, 183);
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
    background: rgba(15, 139, 253, 0.04);
    color: rgb(29, 112, 183);
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
    background: rgba(15, 139, 253, 0.16);
    color: rgb(29, 112, 183);
    border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
    color: #676b89;
    border-color: #676b89;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: rgba(68, 72, 109, 0.07);
    color: #676b89;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: rgba(68, 72, 109, 0.17);
    color: #676b89;
}

.p-button.p-button-text {
    background-color: transparent;
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
    background: rgba(15, 139, 253, 0.04);
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-button.p-button-text:enabled:active {
    background: rgba(15, 139, 253, 0.16);
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
    color: #676b89;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
    background: rgba(68, 72, 109, 0.07);
    color: #676b89;
}

.p-button.p-button-text.p-button-plain:enabled:active {
    background: rgba(68, 72, 109, 0.17);
    color: #676b89;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-button .p-button-label {
    transition-duration: 0.2s;
}

.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}

.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: rgb(29, 112, 183);
    background-color: #ffffff;
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
    border-radius: 2rem;
}

.p-button.p-button-icon-only {
    width: 1.986rem;
    padding: 0.429rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 1.986rem;
}

.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.375375rem 0.499625rem;
}

.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.53625rem 0.71375rem;
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
}

.p-fluid .p-button {
    width: 100%;
}

.p-fluid .p-button-icon-only {
    width: 2.286rem;
}

.p-fluid .p-buttonset {
    display: flex;
}

.p-fluid .p-buttonset .p-button {
    flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
}

.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: rgba(68, 72, 109, 0.12);
    color: #44486d;
    border-color: transparent;
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-color: transparent;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: rgba(68, 72, 109, 0.07);
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(68, 72, 109, 0.04);
    color: rgba(68, 72, 109, 0.07);
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(68, 72, 109, 0.16);
    color: rgba(68, 72, 109, 0.07);
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: rgba(68, 72, 109, 0.07);
    border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(68, 72, 109, 0.04);
    border-color: transparent;
    color: rgba(68, 72, 109, 0.07);
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(68, 72, 109, 0.16);
    border-color: transparent;
    color: rgba(68, 72, 109, 0.07);
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #873efe;
    border: 1px solid transparent;
}

.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: #6c1af2;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
    background: #5310c1;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #873efe;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(135, 62, 254, 0.04);
    color: #873efe;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(135, 62, 254, 0.16);
    color: #873efe;
    border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #873efe;
    border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(135, 62, 254, 0.04);
    border-color: transparent;
    color: #873efe;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(135, 62, 254, 0.16);
    border-color: transparent;
    color: #873efe;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #0bd18a;
    border: 1px solid transparent;
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #049b65;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #017e52;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #0bd18a;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(11, 209, 138, 0.04);
    color: #0bd18a;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(11, 209, 138, 0.16);
    color: #0bd18a;
    border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #0bd18a;
    border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(11, 209, 138, 0.04);
    border-color: transparent;
    color: #0bd18a;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(11, 209, 138, 0.16);
    border-color: transparent;
    color: #0bd18a;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
    color: #44486d;
    background: #eee500;
    border: 1px solid transparent;
}

.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: #d1c901;
    color: #44486d;
    border-color: transparent;
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: #bab302;
    color: #44486d;
    border-color: transparent;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #eee500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(238, 229, 0, 0.04);
    color: #eee500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(238, 229, 0, 0.16);
    color: #eee500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #eee500;
    border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(238, 229, 0, 0.04);
    border-color: transparent;
    color: #eee500;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(238, 229, 0, 0.16);
    border-color: transparent;
    color: #eee500;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #ec4dbc;
    border: 1px solid transparent;
}

.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #e80ea6;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #b30c81;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ec4dbc;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(236, 77, 188, 0.04);
    color: #ec4dbc;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(236, 77, 188, 0.16);
    color: #ec4dbc;
    border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #ec4dbc;
    border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(236, 77, 188, 0.04);
    border-color: transparent;
    color: #ec4dbc;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(236, 77, 188, 0.16);
    border-color: transparent;
    color: #ec4dbc;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #f10d0d;
    border: 1px solid transparent;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #e73a3a;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: none;
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: #c42424;
    color: #ffffff;
    border-color: transparent;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #f10d0d;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(252, 97, 97, 0.04);
    color: #f10d0d;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(252, 97, 97, 0.16);
    color: #f10d0d;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #f10d0d;
    border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(252, 97, 97, 0.04);
    border-color: transparent;
    color: #f10d0d;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(252, 97, 97, 0.16);
    border-color: transparent;
    color: #f10d0d;
}

.p-button.p-button-link {
    color: rgb(29, 112, 183);
    background: transparent;
    border: transparent;
}

.p-button.p-button-link:enabled:hover {
    background: transparent;
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: none;
    border-color: transparent;
}

.p-button.p-button-link:enabled:active {
    background: transparent;
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
}

.p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #676b89;
    color: #fff;
}

.p-speeddial-action:hover {
    background: #44486d;
    color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
}

.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
}

.p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.2);
}

.p-splitbutton {
    border-radius: 6px;
}

.p-splitbutton.p-button-outlined > .p-button {
    background-color: transparent;
    color: rgb(29, 112, 183);
    border: 1px solid;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(15, 139, 253, 0.04);
    color: rgb(29, 112, 183);
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(15, 139, 253, 0.16);
    color: rgb(29, 112, 183);
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #676b89;
    border-color: #676b89;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #676b89;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(68, 72, 109, 0.17);
    color: #676b89;
}

.p-splitbutton.p-button-text > .p-button {
    background-color: transparent;
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(15, 139, 253, 0.04);
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(15, 139, 253, 0.16);
    color: rgb(29, 112, 183);
    border-color: transparent;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #676b89;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #676b89;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(68, 72, 109, 0.17);
    color: #676b89;
}

.p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-splitbutton.p-button-rounded {
    border-radius: 2rem;
}

.p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
}

.p-splitbutton.p-button-sm > .p-button {
    font-size: 0.875rem;
    padding: 0.375375rem 0.499625rem;
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: 0.875rem;
}

.p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: 0.53625rem 0.71375rem;
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: transparent;
    color: rgba(68, 72, 109, 0.07);
    border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.04);
    color: rgba(68, 72, 109, 0.07);
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(68, 72, 109, 0.16);
    color: rgba(68, 72, 109, 0.07);
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: transparent;
    color: rgba(68, 72, 109, 0.07);
    border-color: transparent;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.04);
    border-color: transparent;
    color: rgba(68, 72, 109, 0.07);
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(68, 72, 109, 0.16);
    border-color: transparent;
    color: rgba(68, 72, 109, 0.07);
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: transparent;
    color: #873efe;
    border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(135, 62, 254, 0.04);
    color: #873efe;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(135, 62, 254, 0.16);
    color: #873efe;
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: #873efe;
    border-color: transparent;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(135, 62, 254, 0.04);
    border-color: transparent;
    color: #873efe;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(135, 62, 254, 0.16);
    border-color: transparent;
    color: #873efe;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: transparent;
    color: #0bd18a;
    border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(11, 209, 138, 0.04);
    color: #0bd18a;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(11, 209, 138, 0.16);
    color: #0bd18a;
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: transparent;
    color: #0bd18a;
    border-color: transparent;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(11, 209, 138, 0.04);
    border-color: transparent;
    color: #0bd18a;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(11, 209, 138, 0.16);
    border-color: transparent;
    color: #0bd18a;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: transparent;
    color: #eee500;
    border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(238, 229, 0, 0.04);
    color: #eee500;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(238, 229, 0, 0.16);
    color: #eee500;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: transparent;
    color: #eee500;
    border-color: transparent;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(238, 229, 0, 0.04);
    border-color: transparent;
    color: #eee500;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(238, 229, 0, 0.16);
    border-color: transparent;
    color: #eee500;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: transparent;
    color: #ec4dbc;
    border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(236, 77, 188, 0.04);
    color: #ec4dbc;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(236, 77, 188, 0.16);
    color: #ec4dbc;
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: transparent;
    color: #ec4dbc;
    border-color: transparent;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(236, 77, 188, 0.04);
    border-color: transparent;
    color: #ec4dbc;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(236, 77, 188, 0.16);
    border-color: transparent;
    color: #ec4dbc;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: transparent;
    color: #f10d0d;
    border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(252, 97, 97, 0.04);
    color: #f10d0d;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(252, 97, 97, 0.16);
    color: #f10d0d;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: transparent;
    color: #f10d0d;
    border-color: transparent;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(252, 97, 97, 0.04);
    border-color: transparent;
    color: #f10d0d;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(252, 97, 97, 0.16);
    border-color: transparent;
    color: #f10d0d;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-carousel .p-carousel-indicators {
    padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: rgba(68, 72, 109, 0.12);
    width: 1.429rem;
    height: 0.572rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: rgba(68, 72, 109, 0.15);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-datatable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-paginator-bottom {
    border-width: 0;
    border-radius: 0;
}

.p-datatable .p-datatable-header {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 1px 0 1px 0;
    padding: 0.571rem 0.571rem;
    font-weight: 500;
}

.p-datatable .p-datatable-footer {
    background: transparent;
    color: rgb(29, 112, 183);
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
}

.p-datatable .p-datatable-thead > tr > th {
    white-space: nowrap;
    text-align: center;
    padding: 0.571rem 0.571rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: rgb(29 112 183);
    transition: box-shadow 0.2s;
    font-style: oblique;
    user-select: none;
}

.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 0.571rem 0.571rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #8a8ea6;
    background: transparent;
    user-select: none;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #494c5b;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
    vertical-align: baseline !important;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: rgb(29, 112, 183);
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #8a8ea6;
}

.p-datatable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: #8a8ea6;
}

.p-datatable .p-sortable-column.p-highlight {
    background: rgba(68, 72, 109, 0.12);
    color: #8a8ea6;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #8a8ea6;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: rgba(68, 72, 109, 0.12);
    color: #8a8ea6;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--surface-600);
}

.p-datatable .p-sortable-column:focus {
    box-shadow: 0 none;
    outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
    background: transparent;
    color: #44486d;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: center;
    border-bottom: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.329rem 0.571rem;
    user-select: none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr:focus {
    outline: 0.15rem solid transparent;
     outline-offset: 0.15rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 rgb(29, 112, 183);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 rgb(29, 112, 183);
}

.p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-datatable .p-column-resizer-helper {
    background: rgb(29, 112, 183);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: transparent;
}

.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-scroller-viewport
    > .p-scroller
    > .p-datatable-table
    > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-scroller-viewport
    > .p-scroller
    > .p-datatable-table
    > .p-datatable-tfoot {
    background-color: #f8fcff;
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines
    .p-datatable-tbody
    > tr:last-child
    > td:last-child {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines
    .p-datatable-thead
    + .p-datatable-tfoot
    > tr
    > td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines
    .p-datatable-thead
    + .p-datatable-tfoot
    > tr
    > td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
        .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
        .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
        .p-datatable-tfoot
    )
    .p-datatable-tbody
    > tr:last-child
    > td {
    border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
        .p-datatable-tfoot
    )
    .p-datatable-tbody
    > tr:last-child
    > td:last-child {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: transparent;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler {
    color: #ffffff;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler:hover {
    color: #ffffff;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.2855rem 0.2855rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.2855rem 0.2855rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.2145rem 0.2855rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 0.71375rem 0.71375rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.71375rem 0.71375rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 0.53625rem 0.71375rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-paginator-bottom {
    border-width: 1px 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-dataview-header {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 1px 0 1px 0;
    padding: 0.571rem 0.571rem;
    font-weight: 500;
}

.p-dataview .p-dataview-content {
    background: transparent;
    color: #44486d;
    border: 0 none;
    padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid rgba(68, 72, 109, 0.07);
    border-width: 0 0 0 0;
}

.p-dataview .p-dataview-footer {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
    padding: 1.143rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.flex {
    align-items: baseline;
}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-column-filter-overlay {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    min-width: 12.5rem;
    margin-top: 0.5rem;
    opacity: 0.95;
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.286rem;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.429rem 0.286rem;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #44486d;
    background: rgba(68, 72, 109, 0.07);
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.429rem 0.286rem;
    border-bottom: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.143rem;
    border-bottom: 1px solid rgba(68, 72, 109, 0.17);
}

.p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-remove-button {
    margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.714rem 1.143rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.143rem;
}

.fc.fc-unthemed .fc-view-container th {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid rgba(68, 72, 109, 0.17);
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid rgba(68, 72, 109, 0.17);
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: #027eef;
    border: 1px solid #027eef;
    color: #ffffff;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    color: #ffffff;
    background: rgb(29, 112, 183);
    border: 1px solid rgb(29, 112, 183);
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #027eef;
    color: #ffffff;
    border-color: #027eef;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #0270d5;
    color: #ffffff;
    border-color: #0270d5;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "\e900";
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "\e901";
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    color: #44486d;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: rgba(68, 72, 109, 0.12);
    border-color: transparent;
    color: #44486d;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
    color: #ffffff;
}

.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #027eef;
    border-color: #027eef;
    color: #ffffff;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    z-index: 1;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: rgba(68, 72, 109, 0.17);
}

.fc.fc-theme-standard .fc-view-harness th {
    background: transparent;
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.fc.fc-theme-standard .fc-view-harness td {
    color: #44486d;
    border-color: rgba(68, 72, 109, 0.17);
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: transparent;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.714rem 1.143rem;
    background: transparent;
    color: #44486d;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "PrimeIcons" !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:before {
    content: "\e90b";
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    border-top: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #ffffff;
    background: #027eef;
    border-color: #027eef;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-event.fc-daygrid-block-event
    .fc-event-main {
    color: #ffffff;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-event.fc-daygrid-dot-event
    .fc-daygrid-event-dot {
    background: #027eef;
    border-color: #027eef;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: transparent;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: #ffffff;
    background: rgb(29, 112, 183);
    border: 1px solid rgb(29, 112, 183);
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #027eef;
    color: #ffffff;
    border-color: #027eef;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #0270d5;
    color: #ffffff;
    border-color: #0270d5;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: 0.6;
    color: #ffffff;
    background: rgb(29, 112, 183);
    border: 1px solid rgb(29, 112, 183);
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: "\e900";
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: "\e901";
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: rgba(68, 72, 109, 0.07);
    border: 1px solid transparent;
    color: #44486d;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: rgba(68, 72, 109, 0.12);
    border-color: transparent;
    color: #44486d;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
    color: #ffffff;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #027eef;
    border-color: #027eef;
    color: #ffffff;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
    z-index: 1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.fc.fc-theme-standard a {
    color: #44486d;
}

.fc.fc-theme-standard .fc-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-orderlist .p-orderlist-controls {
    padding: 1.143rem;
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
    background: transparent;
    color: #44486d;
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.714rem 1.143rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 500;
}

.p-orderlist .p-orderlist-filter-container {
    padding: 0.714rem 1.143rem;
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.571rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-orderlist .p-orderlist-list {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    padding: 0.286rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.429rem 0.286rem;
    margin: 0;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
}

.p-orderlist
    .p-orderlist-list:not(.cdk-drop-list-dragging)
    .p-orderlist-item:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even) {
    background: rgba(0, 0, 0, 0);
}

.p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-orderlist-item.cdk-drag-preview {
    padding: 0.429rem 0.286rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
}

.p-organizationchart
    .p-organizationchart-node-content.p-organizationchart-selectable-node:not(
        .p-highlight
    ):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-organizationchart
    .p-organizationchart-node-content.p-highlight
    .p-node-toggler
    i {
    color: #01498b;
}

.p-organizationchart .p-organizationchart-line-down {
    background: rgba(68, 72, 109, 0.17);
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid rgba(68, 72, 109, 0.17);
    border-color: rgba(68, 72, 109, 0.17);
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    border-color: rgba(68, 72, 109, 0.17);
}

.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    padding: 1.143rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-paginator {
    background: transparent;
    color: #44486d;
    border: solid rgba(68, 72, 109, 0.17);
    border-width: 0;
    padding: 0.571rem 0.571rem;
    border-radius: 6px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #44486d;
    min-width: 1.429rem;
    height: 1.429rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    border-color: transparent;
    color: #44486d;
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0.5rem;
}

.p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 1.429rem;
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: rgb(29, 112, 183);
    min-width: 1.429rem;
    height: 1.429rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
    font-style: oblique;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #44486d;
    min-width: 1.429rem;
    height: 1.829rem;
    width: 1.829rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 4px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: rgb(29, 112, 183);
    border-color: rgb(29, 112, 183);
    color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    border-color: transparent;
    color: #44486d;
}

.p-picklist .p-picklist-buttons {
    padding: 1.143rem;
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
    background: transparent;
    color: #44486d;
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.714rem 1.143rem;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 500;
}

.p-picklist .p-picklist-filter-container {
    padding: 0.714rem 1.143rem;
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.571rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-picklist .p-picklist-list {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    padding: 0.286rem;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.429rem 0.286rem;
    margin: 0;
    border: 0 none;
    color: #44486d;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #ffffff;
    background: rgb(29, 112, 183);
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.429rem 0.286rem;
    color: #44486d;
}

.p-picklist
    .p-picklist-list:not(.cdk-drop-list-dragging)
    .p-picklist-item:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even) {
    background: rgba(0, 0, 0, 0);
}

.p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-picklist-item.cdk-drag-preview {
    padding: 0.429rem 0.286rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border: 0 none;
    color: #44486d;
    background: transparent;
    margin: 0;
}

.p-timeline .p-timeline-event-marker {
    border: 2px solid rgb(29, 112, 183);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: rgba(68, 72, 109, 0.07);
}

.p-timeline .p-timeline-event-connector {
    background-color: rgba(68, 72, 109, 0.17);
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
}

.p-tree {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    padding: 1.143rem;
    border-radius: 6px;
}

.p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 6px;
    transition: box-shadow 0.2s;
    padding: 0.429rem 0.571rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #44486d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: #44486d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler,
.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: #ffffff;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler:hover,
.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon:hover {
    color: #ffffff;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.571rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.571rem;
    color: #676b89;
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #0270d5;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 6px;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background-color: transparent;
    color: #44486d;
    padding: 0.429rem 0.571rem;
    transition: box-shadow 0.2s;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: rgb(29, 112, 183);
    color: #ffffff;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: #ffffff;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #44486d;
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content
    .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-treetable .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-paginator-bottom {
    border-width: 1px 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-treetable-header {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 1px 0 1px 0;
    padding: 0.571rem 0.571rem;
    font-weight: 500;
}

.p-treetable .p-treetable-footer {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
}

.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 0.571rem 0.571rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #8a8ea6;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 0.571rem 0.571rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #8a8ea6;
    background: transparent;
}

.p-treetable .p-sortable-column {
    outline-color: rgb(29, 112, 183);
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #8a8ea6;
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #ffffff;
    background: rgb(29, 112, 183);
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #8a8ea6;
}

.p-treetable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: #8a8ea6;
}

.p-treetable .p-sortable-column.p-highlight {
    background: rgba(68, 72, 109, 0.12);
    color: #8a8ea6;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #8a8ea6;
}

.p-treetable .p-treetable-tbody > tr {
    background: transparent;
    color: #44486d;
    transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 0 0;
    padding: 0.429rem 0.571rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
}

.p-treetable
    .p-treetable-tbody
    > tr
    > td
    p-treetablecheckbox
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: #44486d;
}

.p-treetable .p-treetable-tbody > tr:focus {
    outline: 0.15rem solid rgb(29, 112, 183);
    outline-offset: -0.15rem;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #ffffff;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #ffffff;
}

.p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover
    .p-treetable-toggler {
    color: #44486d;
}

.p-treetable .p-column-resizer-helper {
    background: rgb(29, 112, 183);
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: transparent;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.499625rem 0.499625rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.2855rem 0.2855rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.2145rem 0.2855rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 0.71375rem 0.71375rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 0.71375rem 0.71375rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 0.53625rem 0.71375rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 1px 0 1px 0;
    padding: 0.571rem 0.571rem;
    font-weight: 500;
}

.p-virtualscroller .p-virtualscroller-content {
    background: transparent;
    color: #44486d;
    border: 0 none;
    padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
    background: transparent;
    color: #8a8ea6;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.714rem 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
    background: transparent;
    font-weight: 500;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    margin-right: 0.5rem;
}

.p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: rgba(68, 72, 109, 0.07);
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: rgba(68, 72, 109, 0.12);
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: rgba(68, 72, 109, 0.17);
    background: rgba(68, 72, 109, 0.12);
    color: #44486d;
}

.p-accordion .p-accordion-content {
    padding: 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
}

.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header
    .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link,
.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion
    p-accordiontab:first-child
    .p-accordion-header
    .p-accordion-header-link {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-accordion
    p-accordiontab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-card {
    background: transparent;
    color: #44486d;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}

.p-card .p-card-body {
    padding: 0.857rem 1.143rem;
}

.p-card .p-card-title {
    font-size: 1.143rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #8a8ea6;
}

.p-card .p-card-content {
    padding: 1rem 0;
}

.p-card .p-card-footer {
    padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
    background-color: transparent;
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px rgba(68, 72, 109, 0.17);
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 1.143rem;
    background-color: ghostwhite;
    background-image: url(/assets/layout/images/effect-onlight.png);
}

.p-divider.p-divider-vertical:before {
    border-left: 1px rgba(68, 72, 109, 0.17);
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-fieldset {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    border-radius: 6px;
}

.p-fieldset .p-fieldset-legend {
    padding: 0.714rem 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
    background: transparent;
    font-weight: 500;
    border-radius: 6px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0.714rem 1.143rem;
    color: var(--primary-color);
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: rgba(68, 72, 109, 0.07);
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.p-fieldset .p-fieldset-content {
    padding: 1.143rem;
}

.p-panel .p-panel-header {
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.714rem 1.143rem;
    background: transparent;
    color: #44486d;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 500;
}

.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.429rem 0.571rem;
}

.p-panel .p-panel-content {
    padding: 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none;
}

.p-panel .p-panel-footer {
    padding: 0.714rem 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    border-top: 0 none;
}

.p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
}

.p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
}

.p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: transparent;
    border: 0 none;
}

.p-splitter {
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    border-radius: 6px;
    color: #44486d;
}

.p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: rgba(68, 72, 109, 0.17);
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: rgba(68, 72, 109, 0.17);
}

.p-splitter .p-splitter-gutter-resizing {
    background: rgba(68, 72, 109, 0.17);
}

.p-tabview .p-tabview-nav {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 2px 0;
    gap: 3rem;
    justify-content: center;
}

.p-tabview .p-tabview-nav li {
    margin-right: 0.857rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid transparent;
    border-width: 0 0 2px 0;
    border-color: transparent transparent transparent transparent;
    background: transparent;
    color: #8a8ea6;
    padding: 0.571rem 0.429rem;
    font-weight: 500;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 none;
}

.p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    background: transparent;
    border-color: #44486d;
    color: #44486d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    border-color: rgb(29, 112, 183);
    color: rgb(29, 112, 183);
}

.p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
    margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
    background: transparent;
    color: rgb(29, 112, 183);
    width: 2.286rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 none;
}

.p-tabview .p-tabview-panels {
    background: transparent;
    padding: 1.143rem 0;
    border: 0 none;
    color: #44486d;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-toolbar {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 0.714rem 1.143rem;
    border-radius: 6px;
    gap: 0.5rem;
}

.p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
}

.p-confirm-popup {
    background-color: ghostwhite;
    background-image: url(/assets/layout/images/effect-onlight.png);
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}

.p-confirm-popup .p-confirm-popup-content {
    padding: 1.143rem;
}

.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.714rem 1.143rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
}

.p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(236, 236, 249, 0);
    border-bottom-color: #ececf9;
}
.not-allowed {
    cursor: not-allowed;
}

.p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(236, 236, 249, 0);
    border-bottom-color: #ececf9;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ececf9;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #ececf9;
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
    font-weight: 500;
    font-style: oblique;
    color: var(--surface-900) !important;
}

.p-dialog {
    border-radius: 6px;
    box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
    border: 0 none;
    opacity: 0.95;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    padding: 1.286rem 1.714rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: ghostwhite;
    font-size: 1.5rem !important;
    font-style: oblique;
    font-weight: 500;
    color: var(--surface-700);
    user-select: none;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background-image: var(--body-image);
    background-repeat: no-repeat;
    z-index: 0;
}

.p-calendar .p-datepicker {
  //  min-width: 100%;
    left: -7.5px !important;
   // max-width: 50%;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 500;
    font-size: 1.443rem;
    font-style: oblique;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}

.p-dialog .p-dialog-content {
    background: white;
    color: #44486d;
    padding: 0 1.714rem 1.714rem 1.714rem;
    height: 100%;
    width: 100%;
    content: "";
    display: block;
    top: 0;
    left: 0;
    background-image: var(--body-image);
    background-repeat: no-repeat;
    z-index: 0;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ececf9;
    color: #44486d;
    padding: 0 1.714rem 1.714rem 1.714rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.p-overlaypanel {
    background: #ececf9;
    color: #44486d;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}

.p-fluid .p-calendar .p-inputtext {
    background: white;
    width: 1%;
}

.p-inputswitch {
    position: relative;
    display: -webkit-box !important;
    -webkit-user-select: none;
    user-select: none;
}

.p-overlaypanel .p-overlaypanel-close {
    background: #f10d0d;
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -2.1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #027eef;
    color: #ffffff;
}

.p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(236, 236, 249, 0);
    border-bottom-color: #ececf9;
}

.p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(236, 236, 249, 0);
    border-bottom-color: #dadaf3;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ececf9;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #ececf9;
}

.p-sidebar {
    background: #ececf9;
    background-color: ghostwhite;
    background-image: url(/assets/layout/images/effect-onlight.png);
    color: #44486d;
    border: 0 none;
    border-radius: 4px 0 0 4px;
    box-shadow: 0px 18px 50px -4px rgba(68, 72, 109, 0.1);
}

.p-sidebar .p-sidebar-header {
    padding: 0.714rem 1.143rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #676b89;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #44486d;
    border-color: transparent;
    background: rgba(68, 72, 109, 0.07);
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
}

.p-sidebar .p-sidebar-content {
    padding: 0;
}

.p-sidebar .p-sidebar-footer {
    padding: 0.714rem 1.143rem;
}

.p-tooltip .p-tooltip-text {
    background-color: ghostwhite;
    background-image: url(/assets/layout/images/effect-onlight.png);
    color: #44486d;
    padding: 0.429rem 0.571rem 0.7rem 0.7rem;
    margin-left: 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #44486d;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #44486d;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #44486d;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #44486d;
}

.p-tooltip {
    position: absolute;
    display: none;
    padding: 0.25em 0.5rem;
    width: fit-content;
    max-width: fit-content !important;
}
.p-fileupload .p-fileupload-buttonbar {
    background: transparent;
    padding: 0.714rem 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-fileupload .p-fileupload-content {
    background: transparent;
    padding: 2rem 1rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-fileupload .p-progressbar {
    height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
    padding: 0.429rem 0.571rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: #027eef;
    color: #ffffff;
    border-color: #027eef;
}

.p-fileupload-choose:not(.p-disabled):active {
    background: #0270d5;
    color: #ffffff;
    border-color: #0270d5;
}

.p-breadcrumb {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-radius: 6px;
    padding: 0.857rem;
}

.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.2s;
    border-radius: 6px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: #676b89;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #676b89;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #676b89;
}

.p-contextmenu {
    padding: 0.429rem;
    background: #ececf9;
    color: #676b89;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
    border-radius: 6px;
    width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-contextmenu .p-submenu-list {
    padding: 0.429rem;
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
    border-radius: 6px;
}

.p-contextmenu .p-menuitem {
    margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
    margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(68, 72, 109, 0.07);
}

.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text {
    color: #44486d;
}

.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon {
    color: #44486d;
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-contextmenu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-dock .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
}

.p-dock .p-dock-item {
    padding: 0.5rem;
}

.p-dock .p-dock-action {
    width: 4rem;
    height: 4rem;
}

.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
}

.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next,
.p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
}

.p-dock.p-dock-top .p-dock-item-current,
.p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
}

.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
}

.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next,
.p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
}

.p-dock.p-dock-left .p-dock-item-current,
.p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container,
    .p-dock.p-dock-bottom .p-dock-list-container {
        overflow-x: auto;
        width: 100%;
    }

    .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
        margin: 0 auto;
    }

    .p-dock.p-dock-left .p-dock-list-container,
    .p-dock.p-dock-right .p-dock-list-container {
        overflow-y: auto;
        height: 100%;
    }

    .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
        margin: auto 0;
    }

    .p-dock .p-dock-list .p-dock-item {
        transform: none;
        margin: 0;
    }
}

.p-megamenu {
    padding: 0.857rem;
    background: transparent;
    color: #676b89;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-radius: 6px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: #676b89;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #676b89;
    margin-left: 0.5rem;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #44486d;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #44486d;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #44486d;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #44486d;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #44486d;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #44486d;
}

.p-megamenu .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-megamenu .p-megamenu-panel {
    background: #ececf9;
    color: #676b89;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}

.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-megamenu .p-megamenu-submenu {
    padding: 0.429rem;
    width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
    margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
    margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(68, 72, 109, 0.07);
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #44486d;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #44486d;
}

.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.429rem;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
    margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
    margin: 0;
}

.p-megamenu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-menu {
    background: transparent;
    color: #676b89;
    border-radius: 6px;
}

.p-menu .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-menu.p-menu-overlay {
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}

.p-menu .p-submenu-header {
    margin: 0;
    padding: 0.571rem 0.429rem;
    color: #8a8ea6;
    background: transparent;
    font-weight: 500;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-menu .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-menu .p-menuitem {
    margin: 0;
}

.p-menu .p-menuitem:last-child {
    margin: 0;
}

.p-menu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-menubar {
    padding: 0.857rem;
    background: transparent;
    color: #676b89;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-radius: 6px;
}

.p-menubar .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: #676b89;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #676b89;
    margin-left: 0.5rem;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #44486d;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #44486d;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #44486d;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #44486d;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #44486d;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #44486d;
}

.p-menubar .p-submenu-list {
    padding: 0.429rem;
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
    width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
    margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
    margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(68, 72, 109, 0.07);
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #44486d;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #44486d;
}

.p-menubar .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative;
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: #676b89;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-menubar .p-menubar-button:hover {
        color: #44486d;
        background: rgba(68, 72, 109, 0.07);
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 none;
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.429rem;
        background: #ececf9;
        border: 1px solid transparent;
        box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
        width: 100%;
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid rgba(68, 72, 109, 0.17);
        margin: 0.25rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: 0.571rem 0.429rem;
        color: #676b89;
        border-radius: 6px;
        transition: box-shadow 0.2s;
        user-select: none;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-menuitem-text {
        color: #676b89;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-menuitem-icon {
        color: #676b89;
        margin-right: 0.5rem;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-submenu-icon {
        color: #676b89;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover {
        background: rgba(68, 72, 109, 0.07);
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-menuitem-text {
        color: #44486d;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-menuitem-icon {
        color: #44486d;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-submenu-icon {
        color: #44486d;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 none;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        > .p-submenu-icon {
        margin-left: auto;
        transition: transform 0.2s;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem.p-menuitem-active
        > .p-menuitem-link
        > .p-submenu-icon {
        transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.2s;
        transform: rotate(90deg);
    }

    .p-menubar
        .p-menubar-root-list
        .p-submenu-list
        .p-menuitem-active
        > .p-menuitem-link
        > .p-submenu-icon {
        transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 1.713rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 2.855rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 3.997rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 5.139rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 6.281rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}

.p-panelmenu .p-panelmenu-header > a {
    padding: 0.714rem 1.143rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    color: #44486d;
    background: transparent;
    font-weight: 500;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: rgba(68, 72, 109, 0.07);
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: rgba(68, 72, 109, 0.12);
    border-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: rgba(68, 72, 109, 0.17);
    background: rgba(68, 72, 109, 0.12);
    color: #44486d;
}

.p-panelmenu .p-panelmenu-content {
    padding: 0.429rem;
    border: 1px solid rgba(68, 72, 109, 0.17);
    background: transparent;
    color: #44486d;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-menuitem-text {
    color: #676b89;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #44486d;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: #44486d;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: #44486d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-panelmenu-icon {
    margin-right: 0.5rem;
}

.p-panelmenu
    .p-panelmenu-content
    .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none;
}

.p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
    > a,
.p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-disabled).p-highlight:hover
    > a {
    border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-panelmenu
    .p-panelmenu-panel:last-child
    .p-panelmenu-header:not(.p-highlight)
    > a {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-panelmenu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-slidemenu {
    padding: 0.429rem;
    background: transparent;
    color: #676b89;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-radius: 6px;
    width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-slidemenu.p-slidemenu-overlay {
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}

.p-slidemenu .p-slidemenu-list {
    padding: 0.429rem;
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: rgba(68, 72, 109, 0.07);
}

.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-text {
    color: #44486d;
}

.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-icon,
.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-icon {
    color: #44486d;
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
    padding: 0.571rem 0.429rem;
    color: #676b89;
}

.p-slidemenu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 6px;
    background: transparent;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #8a8ea6;
    border: 0 none;
    background: transparent;
    min-width: 1.714rem;
    height: 1.714rem;
    line-height: 1.714rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 12px;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #8a8ea6;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 400;
    color: #44486d;
}

.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -0.857rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: transparent;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-width: 0 0 2px 0;
    gap: 4rem;
}

.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid transparent;
    border-width: 0 0 3px 0;
    border-color: transparent transparent transparent transparent;
    background: transparent;
    color: #8a8ea6;
    padding: 0.571rem 0.429rem;
    font-weight: 400;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 none;
}

.p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
    .p-menuitem-link {
    background: transparent;
    border-color: #44486d;
    color: #44486d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: transparent;
    border-color: rgb(29, 112, 183);
    color: rgb(29, 112, 183);
    font-weight: 500;
    font-size: 1.1rem;
}

.p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: transparent;
    color: rgb(29, 112, 183);
    width: 2.286rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 none;
}

.p-tieredmenu {
    padding: 0.429rem;
    background: transparent;
    color: #676b89;
    border: 1px solid rgba(68, 72, 109, 0.17);
    border-radius: 6px;
    width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.571rem 0.429rem;
    color: #676b89;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #676b89;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #676b89;
    margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #676b89;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #44486d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #44486d;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #44486d;
}

.title-nch {
    font-size: 1.6rem;
    font-weight: 500;
    color: rgb(29, 112, 183);
}

.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
    width: -webkit-fill-available;
}

.p-tieredmenu .p-submenu-list {
    padding: 0.429rem;
    background: #ececf9;
    border: 1px solid transparent;
    box-shadow: 0px 9px 30px rgba(68, 72, 109, 0.06);
}

.p-tieredmenu .p-menuitem {
    margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
    margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: rgba(68, 72, 109, 0.07);
}

.p-tieredmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text {
    color: #44486d;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #44486d;
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
    margin: 0.25rem 0;
}

.p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-tieredmenu .p-menuitem-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-inline-message {
    padding: 0.429rem 0.571rem;
    margin: 0;
    border-radius: 0;
}

.p-inline-message.p-inline-message-info {
    background: rgba(68, 72, 109, 0.07);
    border: solid #873efe;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #44486d;
}

.p-inline-message.p-inline-message-success {
    background: rgba(68, 72, 109, 0.07);
    border: solid #0bd18a;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #44486d;
}

.p-inline-message.p-inline-message-warn {
    background: rgba(68, 72, 109, 0.07);
    border: solid #eee500;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #44486d;
}

.p-inline-message.p-inline-message-error {
    background: rgb(175 176 179 / 7%);
    border: solid #f10d0d;
    border-width: 0 0 0 8px;
    color: #44486d;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #44486d;
}

.p-inline-message .p-inline-message-icon {
    font-size: 1.286rem;
    margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem;
    color: #f10d0d;
    font-weight: 600;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
}

.p-message {
    margin: 1rem 0;
    border-radius: 6px;
}

.p-message .p-message-wrapper {
    padding: 0.8rem 1.357rem;
}

.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-message.p-message-info {
    background: rgba(68, 72, 109, 0.07);
    border: solid #873efe;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-message.p-message-info .p-message-icon {
    color: #44486d;
}

.p-message.p-message-info .p-message-close {
    color: #44486d;
}

.p-message.p-message-success {
    background: rgba(68, 72, 109, 0.07);
    border: solid #0bd18a;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-message.p-message-success .p-message-icon {
    color: #44486d;
}

.p-message.p-message-success .p-message-close {
    color: #44486d;
}

.p-message.p-message-warn {
    background: rgba(68, 72, 109, 0.07);
    border: solid #eee500;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-message.p-message-warn .p-message-icon {
    color: #44486d;
}

.p-message.p-message-warn .p-message-close {
    color: #44486d;
}

.p-message.p-message-error {
    background: rgba(68, 72, 109, 0.07);
    border: solid #f10d0d;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-message.p-message-error .p-message-icon {
    color: #44486d;
}

.p-message.p-message-error .p-message-close {
    color: #44486d;
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 400;
}

.p-message .p-message-icon {
    font-size: 1.286rem;
    margin-right: 0.5rem;
}

.p-message .p-message-summary {
    font-weight: 700;
}

.p-message .p-message-detail {
    margin-left: 0.5rem;
}

.p-toast {
    opacity: 1;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: none;
    border-radius: 6px;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1.143rem 1.357rem;
    border-width: 0 0 0 20px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 1.286rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 400;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 1.286rem;
    height: 1.286rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-toast .p-toast-message.p-toast-message-info {
    background: rgba(68, 72, 109, 0.07);
    border: solid #873efe;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-success {
    background: rgba(68, 72, 109, 0.07);
    border: solid #0bd18a;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: rgba(68, 72, 109, 0.07);
    border: solid #eee500;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: rgba(68, 72, 109, 0.07);
    border: solid #f10d0d;
    border-width: 0 0 0 20px;
    color: #44486d;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #44486d;
}

.p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #44486d;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
    background: rgba(68, 72, 109, 0.15);
    color: #44486d;
}

.p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #44486d;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.15);
    color: #44486d;
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: transparent;
    padding: 1rem;
}

.p-galleria .p-galleria-indicators {
    padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: rgba(68, 72, 109, 0.12);
    width: 1.429rem;
    height: 0.286rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(68, 72, 109, 0.15);
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button {
    background: #676b89;
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button:hover {
    background: #44486d;
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator.p-highlight
    button {
    background: rgb(29, 112, 183);
    color: #ffffff;
}

.p-galleria .p-galleria-thumbnail-container {
    background: #ececf9;
    padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #44486d;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(68, 72, 109, 0.15);
    color: #44486d;
}

.p-galleria
    .p-galleria-thumbnail-container
    .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
    padding: 1rem;
}

.p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-image-action.p-link:last-child {
    margin-right: 0;
}

.p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
}

.p-image-action.p-link i {
    font-size: 1.5rem;
}

.p-avatar {
    background-color: rgba(68, 72, 109, 0.17);
    border-radius: 6px;
}

.p-avatar.p-avatar-lg {
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.2rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
}

.p-avatar-group .p-avatar {
    border: 2px solid transparent;
}

.p-badge {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 500;
    min-width: 1.429rem;
    height: 1.429rem;
    line-height: 1.429rem;
}

.p-badge.p-badge-secondary {
    background-color: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-badge.p-badge-success {
    background-color: #0bd18a;
    color: #ffffff;
}

.p-badge.p-badge-info {
    background-color: #873efe;
    color: #ffffff;
}

.p-badge.p-badge-warning {
    background-color: #eee500;
    color: #44486d;
}

.p-badge.p-badge-danger {
    background-color: #f10d0d;
    color: #ffffff;
}

.p-badge.p-badge-lg {
    font-size: 1.5rem;
    min-width: 2.1435rem;
    height: 2.1435rem;
    line-height: 2.1435rem;
}

.p-badge.p-badge-xl {
    font-size: 2rem;
    min-width: 2.858rem;
    height: 2.858rem;
    line-height: 2.858rem;
}

.p-chip {
    background-color: rgba(68, 72, 109, 0.17);
    color: #44486d;
    border-radius: 16px;
    padding: 0 0.571rem;
}

.p-chip .p-chip-text {
    line-height: 1;
    margin-top: 0.2145rem;
    margin-bottom: 0.2145rem;
}

.p-chip .p-chip-icon {
    margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
}
.p-chip img {
    width: 1.929rem;
    height: 1.929rem;
    margin-left: -0.571rem;
    margin-right: 0.5rem;
}
.p-inputnumber-clear-icon {
    position: absolute;
    color: var(--red-400) !important;
    top: 50%;
    margin-top: -0.8rem !important;
    cursor: pointer;
}
.p-chip .pi-chip-remove-icon {
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    color: red;
}

.p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-inplace .p-inplace-display {
    padding: 0.429rem 0.571rem;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: rgba(68, 72, 109, 0.07);
    color: #44486d;
}

.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 none;
}

.p-progressbar {
    border: 0 none;
    height: 0.571rem;
    background: rgba(68, 72, 109, 0.12);
    border-radius: 6px;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: rgb(29, 112, 183);
}

.p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 0.571rem;
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: transparent;
}

.p-skeleton {
    background-color: rgba(68, 72, 109, 0.07);
    border-radius: 4px;
}

.p-skeleton:after {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0)
    );
}

.p-tag {
    background: rgb(29, 112, 183);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 0.429rem;
    border-radius: 6px;
}

.p-tag.p-tag-success {
    background-color: #0bd18a;
    color: #ffffff;
}

.p-tag.p-tag-info {
    background-color: #873efe;
    color: #ffffff;
}

.p-tag.p-tag-warning {
    background-color: #eee500;
    color: #44486d;
}

.p-tag.p-tag-danger {
    background-color: #f10d0d;
    color: #ffffff;
}

.p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 1rem;
}

.p-terminal {
    background: transparent;
    color: #44486d;
    border: 1px solid rgba(68, 72, 109, 0.17);
    padding: 1.143rem;
}

.p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
}

.p-button-label {
    font-weight: 500;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-style: oblique;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: rgb(29, 112, 183);
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: rgb(29, 112, 183);
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: rgb(29, 112, 183);
}

.p-button.p-button-secondary:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(152, 156, 191, 0.07),
        0 1px 2px 0 black;
}

.p-button.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #75f8c9, 0 1px 2px 0 black;
}

.p-button.p-button-info:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #c39fff, 0 1px 2px 0 black;
}

.p-button.p-button-warning:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #fffa77, 0 1px 2px 0 black;
}

.p-button.p-button-help:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f6a6de, 0 1px 2px 0 black;
}

.p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #feb0b0, 0 1px 2px 0 black;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 rgb(29, 112, 183);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 rgb(29, 112, 183);
}

.custom-timeline.p-timeline.p-timeline-vertical .p-timeline-event-opposite {
    flex: 0;
    padding: 0;
}
.grow {
    transition: all 0.2s ease-in-out;
}
.grow:hover {
    transform: scale(1.1);
}
.scroller {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #9f9f9f;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(29, 112, 183, 0.494);
}

.boxes {
    --size: 18px;
    --duration: 800ms;
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 3);
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes .box {
    width: var(--size);
    height: var(--size);
    top: 0;
    left: 0;
    position: absolute;
    transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
    transform: translate(100%, 0);
    -webkit-animation: box1 var(--duration) linear infinite;
    animation: box1 var(--duration) linear infinite;
}

.boxes .box:nth-child(2) {
    transform: translate(0, 100%);
    -webkit-animation: box2 var(--duration) linear infinite;
    animation: box2 var(--duration) linear infinite;
}

.boxes .box:nth-child(3) {
    transform: translate(100%, 100%);
    -webkit-animation: box3 var(--duration) linear infinite;
    animation: box3 var(--duration) linear infinite;
}

.boxes .box:nth-child(4) {
    transform: translate(200%, 0);
    -webkit-animation: box4 var(--duration) linear infinite;
    animation: box4 var(--duration) linear infinite;
}

.boxes .box > div {
    --background: #5c8df6;
    --top: auto;
    --right: auto;
    --bottom: auto;
    --left: auto;
    --translateZ: calc(var(--size) / 2);
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background);
    top: var(--top);
    right: var(--right);
    bottom: var(--bottom);
    left: var(--left);
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
        translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
    --top: 0;
    --left: 0;
}

.boxes .box > div:nth-child(2) {
    --background: #145af2;
    --right: 0;
    --rotateY: 90deg;
}

.boxes .box > div:nth-child(3) {
    --background: #447cf5;
    --rotateX: -90deg;
}

.boxes .box > div:nth-child(4) {
    --background: #dbe3f4;
    --top: 0;
    --left: 0;
    --translateZ: calc(var(--size) * 3 * -1);
}

@-webkit-keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }

    100% {
        transform: translate(200%, 0);
    }
}

@keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }

    100% {
        transform: translate(200%, 0);
    }
}

@-webkit-keyframes box2 {
    0% {
        transform: translate(0, 100%);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@keyframes box2 {
    0% {
        transform: translate(0, 100%);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@-webkit-keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }

    100% {
        transform: translate(0, 100%);
    }
}

@keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }

    100% {
        transform: translate(0, 100%);
    }
}

@-webkit-keyframes box4 {
    0% {
        transform: translate(200%, 0);
    }

    50% {
        transform: translate(200%, 100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

@keyframes box4 {
    0% {
        transform: translate(200%, 0);
    }

    50% {
        transform: translate(200%, 100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

input[type="file"]::file-selector-button {
    margin-right: 10px;
    border: none;
    background: rgb(29, 112, 183);
    padding: 7px 15px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    width: 55%;
}

input[type="file"]::file-selector-button:hover {
    background: rgb(29, 112, 183, 0.5);
}

.red-button {
    color: red !important;
}

.p-timeline-event {
    display: flex;
    position: relative;
    min-height: 53px;
}

.loader-file {
    width: 10em;
    height: 0.8em;
    border-radius: 8px;
    background-color: transparent;
    margin-top: 0.4rem;
}

.loader-file div {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: var(--primary-color);
    animation: width7435 3s linear infinite;
    transition: all;
}

@keyframes width7435 {
    from {
        /*width: 0;*/
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

@keyframes load {
    50% {
        width: 100%;
    }

    100% {
        right: 0;
        left: unset;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    --color: hsl(0, 0%, 87%);
    --animation: 2s ease-in-out infinite;
}

.loader .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    border: solid 2px rgb(29, 112, 183);
    border-radius: 50%;
    margin: 0 10px;
    background-color: transparent;
    animation: circle-keys var(--animation);
}

.loader .circle .dot {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--color);
    animation: dot-keys var(--animation);
}

.loader .circle .outline {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: outline-keys var(--animation);
}

.circle:nth-child(2) {
    animation-delay: 0.2s;
}

.circle:nth-child(3) {
    animation-delay: 0.5s;
}

.circle:nth-child(4) {
    animation-delay: 0.8s;
}

.circle:nth-child(5) {
    animation-delay: 1.1s;
}

.circle:nth-child(2) .dot {
    animation-delay: 0.2s;
}

.circle:nth-child(3) .dot {
    animation-delay: 0.5s;
}

.circle:nth-child(4) .dot {
    animation-delay: 0.8s;
}

.circle:nth-child(5) .dot {
    animation-delay: 1.1s;
}

.circle:nth-child(1) .outline {
    animation-delay: 0.8s;
}

.circle:nth-child(2) .outline {
    animation-delay: 1.2s;
}

.circle:nth-child(3) .outline {
    animation-delay: 1.4s;
}

.circle:nth-child(4) .outline {
    animation-delay: 1.7s;
}

.circle:nth-child(5) .outline {
    animation-delay: 2s;
}

@keyframes circle-keys {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes dot-keys {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes outline-keys {
    0% {
        transform: scale(0);
        outline: solid 20px var(--color);
        outline-offset: 0;
        opacity: 1;
    }

    100% {
        transform: scale(1);
        outline: solid 0 transparent;
        outline-offset: 20px;
        opacity: 0;
    }
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bg-tb {
    background-color: aliceblue;
}

.bg-notif {
    background-color: rgb(152 154 173 / 7%);
}

.tb-fz {
    font-size: 1.07rem !important;
}

.tc {
    font-size: 1.5rem !important;
    font-style: oblique;
    font-weight: 500;
    color: var(--surface-600);
    user-select: none;
}

.tcb {
    font-size: 1.2rem !important;
    font-style: oblique;
    font-weight: 500;
    color: var(--surface-800);
    user-select: none;
}

.ttc {
    color: rgb(29, 112, 183);
    font-size: 1.25rem !important;
    font-weight: 600;
    font-style: oblique;
}

.layout-preloader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 90vw;
    border-radius: 15px;
    background-color: transparent;
}

.layout-preloader-container .layout-preloader {
    position: relative;
    width: 75px;
    height: 75px;
    background: transparent;
    border: -3px solid #161a1f;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: rgb(29, 112, 183);
    letter-spacing: 4px;
    text-transform: uppercase;
    /* text-shadow: 0 0 10px rgb(29, 112, 183);
    ;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */
}

.layout-preloader-container .layout-preloader:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid rgb(29, 112, 183);
    border-right: 3px solid rgb(29, 112, 183);
    border-radius: 50%;
    animation: preloader-rotate-c 1s linear infinite;
}

.layout-preloader-container .layout-preloader span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: preloader-rotate 1s linear infinite;
}

.layout-preloader-container .layout-preloader span:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(29, 112, 183);
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px rgb(29, 112, 183);
}

@keyframes preloader-rotate-c {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader-rotate {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}
ngx-extended-pdf-viewer #toolbarContainer,
ngx-extended-pdf-viewer .findbar,
ngx-extended-pdf-viewer .secondaryToolbar,
ngx-extended-pdf-viewer .editorParamsToolbar {
    position: relative;
    min-height: 32px;
    background-color: transparent !important;
}
ngx-extended-pdf-viewer .toolbar {
    position: relative;
    left: 0;
    right: 0;
    z-index: 9999;
    cursor: default;
    border: 0;
    border-bottom: 1px solid transparent !important;
}
ngx-extended-pdf-viewer .body,
ngx-extended-pdf-viewer .pdf-viewer input,
ngx-extended-pdf-viewer .pdf-viewer button,
ngx-extended-pdf-viewer .pdf-viewer select {
    font: message-box;
    outline: none;
    background-color: transparent !important;
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg-color);
}
ngx-extended-pdf-viewer #viewerContainer:not(.pdfPresentationMode) {
    transition-duration: 200ms;
    transition-timing-function: ease;
    background-color: transparent !important;
}
.p-overlay {
    position: absolute;
    top: 0;
 }

.no-wrap {
    text-wrap: nowrap;
}
