.layout-breadcrumb {
    ol {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 1rem;
        flex-wrap: wrap;
        color: #938f8f;
        li {
            font-weight: 450;
         }
    }
    .breadcrumb-menu {
        margin: 0;
        padding: 0 1.15rem 0 0.85rem;
        padding-right: 0;
        list-style: none;
        flex-grow: 1;
        color: var(--topbar-item-text-color);
        margin-right: -2rem;

        li {
            margin-left: 1.5rem;
            .breadcrumb-search {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                width: 100%;
                margin-left: 0rem;

                .breadcrumb-searchbutton {
                    display: flex;
                    opacity: 1;
                }

                .search-input-wrapper {
                    position: relative;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);

                    span {
                        width: 100%;

                        .p-inputtext {
                            width: 100%;
                            position: relative;
                            border-radius: 40px;
                            padding: 9px;
                        }

                        i {
                            font-size: 18px;
                            margin-top: -9px;
                            margin-right: 9px;
                        }
                    }
                }

                &.breadcrumb-search-active {
                    .breadcrumb-searchbutton {
                        opacity: 0;
                        display: none;
                        pointer-events: none;
                        cursor: default;
                    }

                    .search-input-wrapper {
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                        border-radius: var(--border-radius);
                        i {
                            display: block;
                        }
                        .p-input-icon-right {
                            input {
                                border-radius: var(--border-radius);
                                width: 100%;
                                background: var(--surface-ground);
                            }
                        }
                    }
                }
            }
        }
    }
}

.content-breadcrumb {
    margin-bottom: 2rem;
    padding: 0 0.5rem;
}
