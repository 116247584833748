$breakpoint: 992px !default;
$scale: 13px !default;

.layout-light {
    --sidebar-shadow: 0px 4px 50px #d9ddfc;
    --sidebar-border: 1px solid transparent;
    --card-shadow: 0px 4px 30px rgba(221, 224, 255, 0.54);
    --body-bg: linear-gradient(180deg, #f6f9fc 0%, #ececf9 100%);
    --body-image: url("/assets/layout/images/effect-onlight.png");
    --body-aafcme: url("/assets/demo/images/logo.png");
    --root-menu-item-hover-bg: rgba(68, 72, 109, 0.07);
    --exception-pages-image: url("/assets/layout/images/pages/exception-onlight.png");
}
