.card {
    // background-color: rgb(235 235 235 / 4%);
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 5px;
    margin-top: 5px;
    // box-shadow: rgb(50 50 93 / 12%) 0px 2px 5px 2px,
    //     rgb(0 0 0 / 9%) 0px 1px 3px -1px;
    // border-radius: 8px;
    .card-header {
        margin-left: 2rem;
        font-weight: 500;
        display: flex;
        color: var(--text-color-secondary);
        align-items: center;
        justify-content: space-between;
    }

    .card-subtitle {
        color: var(--text-color-secondary);
        font-size: 0.857rem;
        font-weight: 500;
        margin-left: 2rem;
    }

    .card-body {
        box-shadow: rgb(224 233 242 / 24%) 3px 3px 6px 0px inset,
            rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        border-radius: 10px;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 70px;
    }
}

.ng-hidden {
    display: none !important;
}
